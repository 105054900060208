<template>
  <div>
    <div class="text-center display-1 font-weight-normal mb-6">
      Por favor, selecione as cores que serão utilizadas no mapa
    </div>
    <v-row
      id="selecionar-cores"
      class="mx-auto"
    >
      <v-col cols="6">
        <input-color
          label="Cor início"
          :selectedColor="colors.corInicio"
          @colorSelected="setCorInicio"
        />
      </v-col>
      <v-col cols="6">
        <input-color
          label="Cor fim"
          :selectedColor="colors.corFim"
          @colorSelected="setCorFim"
        />
      </v-col>
    </v-row>
    <div class="text-center display-1 font-weight-normal mb-6">
      Cores geradas para os {{ midpoint }} setores, uma cor para cada setor
    </div>
    <v-row
      id="range-cores"
      class="mx-auto"
    >
      <v-col cols="12">
        <colors-range
          :startColor="corInicio"
          :finalColor="corFim"
          :midpoint="midpoint"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'ThirdTabContent',

  components: {
    InputColor: () => import('@/components/general/InputColor'),
    ColorsRange: () => import('@/components/general/ColorsRange')
  },

  props: {
    colors: {
      type: Object,
      required: true
    },
    midpoint: {
      type: Number,
      required: true,
      default: 2
    }
  },

  data() {
    return {
      corInicio: null,
      corFim: null
    };
  },

  mounted() {
    this.corInicio = this.colors.corInicio;
    this.corFim = this.colors.corFim;
  },

  watch: {
    corInicio() {
      this.emitEventDataUpdated();
    },

    corFim() {
      this.emitEventDataUpdated();
    }
  },

  methods: {
    setCorInicio(cor) {
      this.corInicio = cor;
    },

    setCorFim(cor) {
      this.corFim = cor;
    },

    emitEventDataUpdated() {
      this.$emit('data:updated', {
        cores: {
          corInicio: this.corInicio,
          corFim: this.corFim
        }
      });
    }
  }
};
</script>
